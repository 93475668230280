import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})

export class  ApiService { 

    private PHP_API_SERVER = "https://gescalibra.pt/bo/api.php";
    private PHP_API_INDEX = "https://gescalibra.pt/bo/index.php";
    private API_KEY = 'b77f72421ece099b99731452a4f4f832';

    private options = { headers: new HttpHeaders().set('Content-Type', 'x-www-form-urlencoded') };

    constructor(private http: HttpClient) {}

    login(login: any) {
        var path = this.PHP_API_INDEX + "?controller=mnhome&action=validateLogin";
        const body = new HttpParams()
        .set('service', login.service)
        .set('username', login.user)
        .set('password', login.password);

        return this.http.post<any>(path, body.toString() ,{
			headers: new HttpHeaders()
			.set('Content-Type', 'application/x-www-form-urlencoded')
		}).pipe(map((res) => {
            var result =  res ? res : null;
            return result;
        }),
        catchError((err) => {
            console.error(err);
            return of(undefined);
        }))
    }

    checkLogin() {
        var path = this.PHP_API_SERVER + '?controller=website&action=checklogin&API_KEY='+this.API_KEY;
        return this.http.get<any>(path ,this.options).pipe(map((res) => {
            var result =  res ? res : null;
            return result;
        }),
        catchError((err) => {
            console.error(err);
            return of(undefined);
        }))
    }

    recoverPassword(username: string){
        var path = this.PHP_API_INDEX + "?controller=mnhome&action=sendEmailReset";
        const body = new HttpParams()
        .set('service', '1')
        .set('username', username);

        return this.http.post<any>(path, body, {
            headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        }).pipe(map((res) => {
            var result =  res ? res : null;
            return result;
        }),
        catchError((err) => {
            console.error(err);
            return of(undefined);
        }))
    }

    getTestimunials(){
        var path = this.PHP_API_SERVER + "?controller=website&action=gettestimonies&API_KEY="+ this.API_KEY;
        return this.http.get<any>(path).pipe(map((res) => {
            var result =  res ? res.testimonies : null;
            return result;
        }),
        catchError((err) => {
            console.error(err);
            return of(undefined);
        }))
    }

    requestDemo(demo: Demo){
        var path = this.PHP_API_SERVER + "?controller=website&action=newrequest&API_KEY="+ this.API_KEY;

        const body = new HttpParams()
        .set('company', demo.company)
        .set('ovm', demo.ovm)
        .set('address', demo.address)
        .set('street', demo.street)
        .set('postalcode', demo.postalcode)
        .set('country', demo.country)
        .set('phone', demo.responsable)
        .set('responsable', demo.phone)
        .set('email', encodeURIComponent(demo.email))
        .set('message', demo.message)
        .set('captcha', demo.captcha)

        return this.http.post<any>(path, body.toString(),{
            headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        }).pipe(map((res) => {
            var result =  res ? res : null;
            return result;
        }),
        catchError((err) => {
            console.error(err);
            return of(undefined);
        }))
    }

    sendMessage(message: Contacts){
        var path = this.PHP_API_SERVER + "?controller=website&action=sendcontact&API_KEY="+ this.API_KEY;

        const body = new HttpParams()
        .set('company', message.company)
        .set('responsable', message.responsable)
        .set('email', encodeURIComponent(message.email))
        .set('phone', message.phone)
        .set('subject', message.subject)
        .set('message', message.message)
        .set('captcha', message.captcha)


        return this.http.post<any>(path, body.toString(),{
            headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        }).pipe(map((res) => {
            var result =  res ? res : null;
            return result;
        }),
        catchError((err) => {
            console.error(err);
            return of(undefined);
        }))
    }

    getBlogPosts() { 
        var path = this.PHP_API_SERVER + '?controller=website&action=getnews&API_KEY='+this.API_KEY;
        return this.http.get<any>(path ,this.options).pipe(map((res) => {
            var result =  res ? res : null;
            return result; 
        }),
        catchError((err) => {
            console.error(err);
            return of(undefined);
        }))
    }

    getSinglePost(id: number) {
        var path = this.PHP_API_SERVER + '?controller=website&action=getnewsdetail&API_KEY='+this.API_KEY + '&id='+id;
        return this.http.get<any>(path ,this.options).pipe(map((res) => {
            var result =  res ? res : null;
            return result;
        }),
        catchError((err) => {
            console.error(err);
            return of(undefined);
        }))
    }

    getAllcountries(){
        var path = this.PHP_API_INDEX + '?controller=mncountryregion&action=getcountryregion';
        return this.http.get<any>(path ,this.options).pipe(map((res) => {
            var result =  res ? res : null;
            return result;
        }),
        catchError((err) => {
            console.error(err);
            return of(undefined);
        }))
    }
}

export class Demo {
    company!: string;
    ovm!: string;
    responsable!: string;
    email!: string;
    phone!: string;
    address!: string;
    street!: string;
    postalcode!: string;
    country!: string;
    message?: string;
    captcha!: string;
}

export class Contacts {
    company!: string;
    responsable!: string;
    email!: string;
    phone!: string;
    subject!: string;
    message?: string;
    captcha!: string;
}