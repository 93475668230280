import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiService } from '../services/api';

declare var  loadPageFunction: any;


@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

  POST_ID: number = 0;

  blogPost = {
    "image" : 'assets/img/blog_6.jpg',
    "title" : 'How to promote your product with this awesome landing page',
    "description": "<p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.</p>",
    "created_at": new Date()
  }

  constructor(private api: ApiService, private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // this.getBlogPost();
    this.blogPost.description = this.sanitizer.sanitize(SecurityContext.HTML, this.blogPost.description);
  }

  loadPageUpdate(){
    setTimeout(() => {
      loadPageFunction.loadPage();
    }, 200);
  }

  getBlogPost(){
    this.api.getSinglePost(this.POST_ID).subscribe(res => {
      if(res.code == 200) {
        this.blogPost = res.blog;
      }
      else{
        this.router.navigate(['/blog']);
      }
    })
  }

}
