import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiService, Contacts, Demo } from '../services/api';

declare var  loadPageFunction: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  loginForm!: FormGroup;
  demoForm!: FormGroup;
  contactusForm!: FormGroup;

  errorMessage: boolean = false;
  successMessage: boolean = false;
  messageError:string = '';
  messageSuccess: string = '';

  listOfTestimunials: any[] = [];
  listOfCountries: any[] = [];

  userRecover: string = '';

  constructor(@Inject('language') public language: any, private formBuilder: FormBuilder, private modalService: NgbModal, private recaptchaV3Service: ReCaptchaV3Service, private api: ApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
	this.route.queryParams
	.subscribe(params => {
		if(typeof params.alertDanger !== "undefined" && this.language[params.alertDanger] != "") {
			this.openError(this.language[params.alertDanger]);
		}
	});
	
    this.initFormLogin();
    this.initFormDemo();
    this.initFormContacts();
    this.getAllcountries();
    this.getAllTestimunials();
    this.checkLogin();
  }

  checkLogin(){
    this.api.checkLogin().subscribe(res => {
      if(res.code == 200) {
        window.location.href = res.href;
      }
    })
  }

  // Login Form
  get f() {
    return this.loginForm.controls;
  }
  // Demo Form
  get d(){
    return this.demoForm.controls;
  }
  // Contacts Form
  get c(){
    return this.contactusForm.controls;
  }

  initFormDemo(){
    this.demoForm = this.formBuilder.group(
      {
        company: ['',Validators.required],
        ovm: ['', Validators.required],
        responsable: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', Validators.required],
        address: ['', Validators.required],
        postalcode: ['', Validators.required],
        street: ['', Validators.required],
        message: [''],
        country: ['193-3', Validators.required],
      }
    )
  }

  initFormContacts() {
    this.contactusForm= this.formBuilder.group(
      {
        company: ['',Validators.required],
        responsable: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: [''],
        subject: ['', Validators.required],
        message: ['', Validators.required],
      }
    )
  }

  initFormLogin(){
    this.loginForm = this.formBuilder.group(  
      {
        user: ['', [Validators.required]],
        password: ['', Validators.required],
      }
    )
  }

  getAllcountries(){
    this.api.getAllcountries().subscribe(res => {
      if(res){
        this.listOfCountries = res
      }
    })
  }

  
  getAllTestimunials(){
    this.api.getTestimunials().subscribe(res => {
      if(res) {
        this.listOfTestimunials = res;
        this.loadTestim();
      }
    })
  }

  loadTestim(){
    setTimeout(() => {
      loadPageFunction.loadTestimony();
    }, 200);
  }

  login(){
    if(this.checkFormValid()){
      const login = {
        'service': 1,
        'user': this.f.user.value,
        'password': this.f.password.value,
      }
      
      this.api.login(login).subscribe(res => {
        if(res.code == 200){
          window.location.href = res.href;
        }
        else {
          this.openError(res.msgTxt);
        }
      })
    }
  }

  recoverUserPass(){
    if(this.userRecover != ''){
      this.api.recoverPassword(this.userRecover).subscribe(res => {
        if(res.code == 200) {
          this.openSucess(res.msgTxt);
          this.modalService.dismissAll();
        }
        else {
          this.openError(res.msgTxt);
        }
      })
    }
    else{
      let errorMsg = 'Nome de utilizador inválido';
      this.openError(errorMsg);
    }
  }


 
  checkFormValid(): boolean {
    if(this.loginForm.valid){
      return true;
    }
    let errMsg = "Preencher todos os campos";
    this.openError(errMsg);
    return false;
  }

  requestDemo(){
    if(this.checkFormDemoValid()){

      let demo = new Demo();
      this.recaptchaV3Service.execute('requestDemo').subscribe((token: string)=> {
        if(token){
          demo.company = this.d.company.value;
          demo.ovm = this.d.ovm.value;
          demo.responsable = this.d.responsable.value;
          demo.email = this.d.email.value;
          demo.phone = this.d.phone.value;
          demo.address = this.d.address.value;
          demo.postalcode = this.d.postalcode.value;
          demo.street = this.d.street.value;
          demo.country = this.d.country.value;
          demo.message = this.d.message.value;
          demo.captcha = token;

          this.api.requestDemo(demo).subscribe(res => {
            if(res.code == 200) {
              this.openSucess(res.msgTxt);
              this.demoForm.reset();
            }
            else{
                this.openError(res.msgTxt);
            }
          })
        }
      })
    }
  }

  checkFormDemoValid(){
    if(this.demoForm.valid){
      return true;
    }
    let message = 'Os campos assinalados com * são obrigatórios.';
    this.openError(message);
    return false;
  }

  sendEmaiContactUs(){
    if(this.checkFormContactsValid()){

      let contact = new Contacts();
      this.recaptchaV3Service.execute('sendEmaiContactUs').subscribe((token: string)=> {
        if(token){
          contact.company = this.c.company.value;
          contact.responsable = this.c.responsable.value;
          contact.email = this.c.email.value;
          contact.phone = this.c.phone.value;
          contact.message = this.c.message.value;
          contact.subject = this.c.subject.value;
          contact.captcha = token;

          this.api.sendMessage(contact).subscribe(res => {
            if(res.code == 200) {
              this.openSucess(res.msgTxt);
              this.contactusForm.reset();
            }
            else{
              this.openError(res.msgTxt);
            }
          })
        }
      })      
    }
  }

  checkFormContactsValid(): boolean{
    if(this.contactusForm.valid){
      return true;
    }
    let message = 'Os campos assinalados com * são obrigatórios.';
    this.openError(message);
    return false;
  }
  // MODALS

  open(content) {
    this.modalService.open(content, {centered: true }).result.then((result) => {})
    .catch(() => {});
  }

  openError(msg: string){
    let self = this;
    this.errorMessage = true;
    this.messageError = msg;
    setTimeout(function () {
      this.messageError = '';
      self.closeError();
    }, 3000);
  }

  openSucess(msg: string) {
    let self = this;
    this.successMessage = true;
    this.messageSuccess = msg;
    setTimeout(function () {
      self.closeSuccess();
      this.messageSuccess = '';
    }, 3000);
  }
  
  closeError() {
    this.errorMessage = false;
  }

  closeSuccess(){
    this.successMessage = false;
  }
}
