import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../services/api';

declare var  loadPageFunction: any;

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  listOfPosts: any[] = [];

  page = 1;
  pageSize = 4;

  constructor(private api: ApiService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getAllBlogPosts();
    this.listOfPosts.forEach(element => {
      element.description = this.sanitizer.sanitize(SecurityContext.HTML, element.description);
    })
  }

  loadPageUpdate(){
    setTimeout(() => {
      loadPageFunction.loadPage();
    }, 200);
  }

  getAllBlogPosts(){
    this.api.getBlogPosts().subscribe(res => {
      if(res.code == 200){
        this.listOfPosts = res.news;
      }
    })
  }

}
