<div class="blog blog-right">
    <div class="main" id="top">
        <div class="blog">
            <div id="scrollUp" title="Deslocar para o topo">
                <i class="icofont-bubble-up"></i>
            </div>
            <div class="main">
                <app-header></app-header>
                <section class="section breadcrumb-area overlay-dark d-flex align-items-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <!-- Breamcrumb Content -->
                                <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                                    <h3 class="text-white">Blog</h3>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a (click)="loadPageUpdate()" [routerLink]="['/home']">Home</a></li>
                                        <li class="breadcrumb-item active">Blog</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="blog" class="section blog-area ptb_100">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-6" *ngFor="let post of listOfPosts | slice: (page-1) * pageSize : page * pageSize">
                                <!-- Single Blog -->
                                <div class="single-blog res-margin">
                                    <!-- Blog Thumb -->
                                    <div class="blog-thumb">
                                        <a (click)="loadPageUpdate()" [routerLink]="['/blog-details',post.idnews]"><img src="{{post.image}}" alt=""></a>
                                    </div>
                                    <!-- Blog Content -->
                                    <div class="blog-content p-4">
                                        <!-- Meta Info -->
                                        <ul class="meta-info d-flex justify-content-between">
                                            <li>Por <a (click)="loadPageUpdate()" [routerLink]="['/blog-details',post.idnews]">GESCALIBRA</a></li>
                                            <li><a (click)="loadPageUpdate()" [routerLink]="['/blog-details',post.idnews]">{{post.created_at | date: 'mediumDate'}}</a></li>
                                        </ul>
                                        <!-- Blog Title -->
                                        <h3 class="blog-title my-3"><a (click)="loadPageUpdate()" [routerLink]="['/blog-details', post.idnews]">{{post.title}}</a></h3>
                                        <div class="blog-description" [innerHTML]="post.description"></div>
                                        <a class="blog-btn mt-3" (click)="loadPageUpdate()" [routerLink]="['/blog-details', post.idnews]">Ler mais</a>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="row" *ngIf="listOfPosts.length >= 1">
                            <div class="col-12">
                                <!-- Pagination -->
                                <div class="pagination justify-content-center">
                                    <ngb-pagination
                                        [(page)]="page"
                                        [pageSize]="pageSize"
                                        class="my-pagination" 
                                        [collectionSize]="listOfPosts.length">
                                    </ngb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <app-footer></app-footer>
            </div>
        </div>
    </div>
</div>