import { Component } from '@angular/core';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'gescalibraLandingPage';

  constructor(
    private Angulartics2Facebook: Angulartics2Facebook, 
		private Angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics, 
		private Angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
  ){
    Angulartics2Facebook.startTracking();
    Angulartics2GoogleAnalytics.startTracking();
    Angulartics2GoogleTagManager.startTracking();
  }
}
