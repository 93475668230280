<div id="scrollUp" title="Deslocar para o topo">
    <i class="icofont-bubble-up"></i>
</div>
<div class="main top" id="top">
    <app-header></app-header>
    <div class="blog">
        <section class="section breadcrumb-area overlay-dark d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Breamcrumb Content -->
                        <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                            <h3 class="text-white">Blog</h3>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a (click)="loadPageUpdate()" [routerLink]="['/home']">Home</a></li>
                                <li class="breadcrumb-item"><a (click)="loadPageUpdate()" [routerLink]="['/blog']">Blog</a></li>
                                <li class="breadcrumb-item active">Post</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <section id="blog" class="section blog-area ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12">
                    <!-- Single Blog Details -->
                    <article class="single-blog-details">
                        <!-- Blog Thumb -->
                        <div class="blog-thumb">
                            <a><img src="{{blogPost.image}}" alt=""></a>
                        </div>
                        <!-- Blog Content -->
                        <div class="blog-content appia-blog">
                            <!-- Meta Info -->
                            <div class="meta-info d-flex flex-wrap align-items-center py-2">
                                <ul>
                                    <li class="d-inline-block p-2">Por <a>GESCALIBRA</a></li>
                                    <li class="d-inline-block p-2"><a>{{blogPost.created_at | date: 'mediumDate'}}</a></li>
                                </ul>
                            </div>
                            <!-- Blog Details -->
                            <div class="blog-details">
                                <h3 class="blog-title py-2 py-sm-3"><a>{{blogPost.title}}</a></h3>
                                <div [innerHTML]="blogPost.description"></div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>